<template>

  <el-container style="height:calc(93.5vh);">
    <el-header class="header">
      <span class="left el-icon-arrow-left" @click="returnOn"></span>
      排班管理
    </el-header>
    <el-container>
      <el-main style="height:calc(93.5vh);width:60%;border-right:1px solid #e9e9e9">
        <el-calendar :first-day-of-week="7">
          <template v-slot:dateCell="{data}">
            <p :class="data.isSelected ? 'is-selected' : ''" style="width:100%;height:100%;margin:0;"
              @click="pushTime(data.day)">
              {{ data.day.split('-').slice(1).join('-') }} {{ data.isSelected? '✔️' : ''}}
            </p>
            <!-- timeList.indexOf(data.day) !== -1 -->
          </template>
        </el-calendar>
      </el-main>
      <el-main style="height:calc(93.5vh);width:40%;">
        <div class="content">
          <div class="card" v-for="(item,index) in paibanList" :key="index">
            <div class="title">
              <span>{{  item.name}}</span> {{ item.stat }}-{{ item.end }}
            </div>
            <div class="box">
              <div class="b-list" v-for="(item1,i) in item.person" :key="i" @click="remuser(item1.realname,item1.id)">
                <img :src="$getimgsrc(item1.avatar)" alt="">
                <span>{{  item1.realname}}</span>
              </div>
              <div class="b-list a-list el-icon-plus" @click="huanxin(item.id)">添加</div>
            </div>
          </div>
          <!-- <div class="card">
            <div class="title">
              <span>下午</span> 13:00 - 16:30
            </div>
            <div class="box">
              <div class="b-list" v-for="(item,i) in 5" :key="i" @click="dialogShow=true,dialogTitle='郭小涛'">
                <img src="@/assets/images/avatar.png" alt="">
                <span>郭小涛</span>
              </div>
              <div class="b-list a-list el-icon-plus" @click="addDiaLogShow=true">添加</div>
            </div>
          </div>
          <div class="card">
            <div class="title">
              <span>晚上</span> 18:00 - 21:30
            </div>
            <div class="box">
              <div class="b-list" v-for="(item,i) in 5" :key="i" @click="dialogShow=true,dialogTitle='顾小敏'">
                <img src="@/assets/images/avatar.png" alt="">
                <span>顾小敏</span>
              </div>
              <div class="b-list a-list el-icon-plus" @click="addDiaLogShow=true">添加</div>
            </div>
          </div> -->
        </div>
      </el-main>
    </el-container>

    <el-dialog class="dialog-btn" :title="dialogTitle" :visible.sync="dialogShow" center :before-close="handleClose">
      <div class="body">
        <el-button class="button" type="danger" icon="el-icon-delete" @click="delStaff">移除</el-button>
        <!-- <el-button class="button" type="primary" @click="dialogShow=false,tehDiaLogShow=true,cellRadio = null"> <span
            class="iconfont icon-qiehuan">换班</span>
        </el-button> -->
      </div>
    </el-dialog>

    <!-- 换班人员 -->
    <el-dialog class="dialog" title="换班人员" center :visible.sync="tehDiaLogShow" width="40%" :before-close="handleClose">
      <div class="alert el-icon-warning">
        合理的排班会让工作更便捷高效
      </div>
      <el-container style="height:490px;">
        <el-main class="cell-list">
          <el-radio-group v-model="cellRadio">
            <el-radio :label="i" v-for="(item,i) in 20" :key="i" @change="changeTeacher">
              <div class="title">
                <img src="@/assets/images/avatar.png" alt="">
                顾小敏(美容师)
              </div>
            </el-radio>
          </el-radio-group>
        </el-main>
      </el-container>
    </el-dialog>
    <el-dialog class="dialog-change" title="换班信息" :visible.sync="changeShift" center :before-close="handleClose">
      <div class="body">
        <div class="card">
          <div class="left">
            <div class="name">
              李小红
            </div>
            <div class="time">
              1月13日
            </div>
            <div class="hours">
              08:00-11:30
            </div>
          </div>
          <div class="center iconfont icon-qiehuan"></div>
          <div class="left">
            <div class="name">
              顾小敏
            </div>
            <div class="time">
              1月13日
            </div>
            <div class="hours">
              08:00-11:30
            </div>
          </div>
        </div>
        <el-button class="button" type="primary" @click="confirmShift">确认换班
        </el-button>
      </div>
    </el-dialog>
    <!-- 添加人员 -->
    <el-dialog class="dialog" title="添加人员" center :visible.sync="addDiaLogShow" width="40%" :before-close="handleClose">
      <div class="alert el-icon-warning">
        合理的排班会让工作更便捷高效
      </div>
      <el-container style="height:470px;">
        <el-main class="cell-list">
          <el-radio-group v-model="addCellRadio">
            <el-radio :label="item.id" v-for="(item,i) in allRenYuan" :key="i" @change="changeRadio">
              <div class="title">
                <img src="@/assets/images/avatar.png" alt="">
                {{ item.realname }}
              </div>
            </el-radio>
          </el-radio-group>
        </el-main>
      </el-container>
    </el-dialog>
  </el-container>
</template>

<script>
  export default {
    data() {
      return {
        timeList: new Date().getFullYear() + '-' + ((new Date().getMonth() + 1) > 9 ? new Date().getMonth() + 1 :
           '0' + (new Date().getMonth() + 1)) + '-' + (new Date().getDate() > 9 ? new Date().getDate() : '0' +
           new Date().getDate()),
        dialogShow: false,
        dialogTitle: '',
        // 换班人员
        tehDiaLogShow: false,
        cellRadio: null,
        // 换班信息
        changeShift: false,
        // 添加人员
        addDiaLogShow: false,
        addCellRadio: null,
        //排班列表
        paibanList:[],
        //换班人员列表
        allRenYuan:[],
        //选中班次id
        bid:null,
        //移除使用用户id
        remuid:null
      }
    },
    mounted() {
      // this.timeList.push(this.$moment(new Date()).format('yyyy-MM-DD'))
      this.getAllUserList()
      this.getPaiBanList()
    },
    methods: {
      //获取全部员工
      getAllUserList(){
        this.$http.post('api/store/user/manystoreperson',{
          type:2
        }).then(res =>{
          this.allRenYuan = res.data.data
        })
      },
      //获取排班列表
      getPaiBanList(){
        this.$http.post('api/store/storeset/classes',{
          time:this.timeList
        }).then(res =>{
          this.paibanList = res.data.data.reverse()
        })
      },
      // 返回上级
      returnOn() {
        this.$router.go(-1)
      },
      // 添加排班时间
      pushTime(time) {
        this.timeList = time
        this.getPaiBanList()
        // if (this.timeList.indexOf(time) !== -1) {
        //   this.timeList.splice(this.timeList.indexOf(time), 1)
        // } else {
        //   this.timeList.push(time)
        // }
        // console.log(this.timeList);
      },
      // 移除员工
      remuser(name,uid){
        this.remuid = uid
        this.dialogTitle = name
        this.dialogShow = true
      },
      delStaff() {
        this.$http.post('api/store/storeset/delclasses',{
          id:this.remuid
        }).then(res =>{
          this.$message.success(res.data.msg)
          this.getPaiBanList()
          this.dialogShow = false
        })
      },
      // 换班
      changeTeacher() {
        this.tehDiaLogShow = false
        this.changeShift = true
      },
      confirmShift() {
        this.changeShift = false
        this.dialogShow = false
        this.$message({
          message: '换班成功',
          type: 'success'
        })
      },
      // 添加人员班次
      huanxin(id){
        // console.log(id);
        this.bid = id
        this.addDiaLogShow=true
        // this.changeRadio(id)
      },
      changeRadio() {
        this.$http.post('api/store/storeset/addclasses',{
          time:this.timeList,
          user_id:this.addCellRadio,
          workid:this.bid
        }).then(res =>{
          this.$message.success(res.data.msg)
          this.addCellRadio = null
          this.getPaiBanList()
        })
        this.addDiaLogShow = false
      },
      // 关闭dialog
      handleClose() {
        this.dialogShow = false
        this.tehDiaLogShow = false
        this.addDiaLogShow = false

      }

    }
  };

</script>
<style lang="less" scoped>
  // 头部
  .el-header {
    position: relative;
    z-index: 1;
    background-color: #fff;
    font-weight: bold;
    font-size: 16px;
    color: #333;
    height: 50px !important;
    line-height: 50px;
    text-align: center;
    border-bottom: 1px solid #e9e9e9;

    .left {
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
      color: #46a6ff;
      font-size: 24px;
    }
  }

  .content {
    padding: 0 10px;
    box-sizing: border-box;

    .card {
      color: #666;

      .title {
        font-size: 12px;
        border-bottom: 1px solid #f2f2f2;
        padding: 10px 0;

        span {
          font-size: 14px;
          font-weight: bold;
        }
      }

      .box {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding: 10px 0;

        .b-list {
          display: flex;
          align-items: center;
          background-color: #f2f2f2;
          width: 90px;
          height: 30px;
          padding: 5px 10px;
          margin: 0 10px 10px 0;
          font-size: 12px;
          border: 1px solid #eaeaea;
          border-radius: 5px;


          img {
            width: 30px;
            height: 30px;
            border-radius: 100%;
            margin-right: 8px;
          }
        }

        .a-list {
          justify-content: center;
          color: #409eff;
          background-color: #fff;
          border: 1px solid #409eff;

          &::before {
            margin-right: 5px;
            font-weight: bold;

          }
        }
      }
    }
  }

  .dialog-btn {
    .body {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .button {
        display: block;
        box-sizing: border-box;
        width: 100%;
        margin: 10px 0;
        font-size: 14px;

        /deep/ .el-icon-delete {
          margin-right: 5px;
        }

        .iconfont {
          font-size: 14px;

          &::before {
            margin-right: 5px;
            font-size: 16px;
          }
        }
      }
    }
  }

  .dialog {
    /deep/.el-dialog__body {
      height: 500px;

    }

    .alert {
      color: #ff9900;
      margin-bottom: 20px;
    }

    .cell-list {
      width: 100%;

      /deep/ .el-radio-group {
        width: 100%;
        position: relative;

        .el-radio {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: 1px solid #f2f2f2;
          padding: 10px;
          margin-bottom: 10px;
          margin-right: 0;

          .title {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 12px;

            img {
              width: 40px;
              height: 40px;
              margin-right: 10px;
            }
          }

          .el-radio__input {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }

    }
  }

  .dialog-change {
    .body {
      text-align: center;

      .card {
        display: flex;
        justify-content: center;
        align-items: center;

        .left {
          margin: 0 30px;
          line-height: 24px;
        }

        .center {
          font-size: 36px;
          font-weight: bold;
          color: #999;
        }
      }

      .button {
        margin-top: 20px;
      }
    }


  }

  /deep/ .el-dialog__header {
    border-bottom: 1px solid #f2f2f2;
    padding: 10px 20px;

    .el-dialog__title {
      font-size: 16px;
      font-weight: bold;
    }
  }

  /deep/ .menu.el-menu {
    border-right: none !important;
    background-color: #f9f9f9;

  }

  .el-menu-item:focus,
  .el-menu-item:hover {
    background-color: #f9f9f9;

  }

  /deep/ .el-main {
    padding: 0 !important;
  }

</style>
